import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-image-gallery/styles/css/image-gallery.css';

import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

import GTWalsheimPro from 'src/assets/fonts/GTWalsheimPro';

const theme = createMuiTheme({
  // shadows: Array(25).fill('none'),
  palette: {
    text: {
      primary: '#0a0a0a',
      secondary: '#ffffff',
    },
    background: {
      default: '#ffffff',
    },
    primary: {
      main: '#FF7800',
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#000000',
    },
  },
  typography: {
    fontFamily: 'GT Walsheim Pro',
    body1: {
      '@media (max-width:600px)': {
        fontSize: 12,
      },
    },
    h1: {
      fontWeight: 900,
      fontSize: 37,
      '@media (max-width:600px)': {
        fontSize: 27,
      },
    },
    h2: {
      fontWeight: 700,
      fontSize: 30,
      '@media (max-width:600px)': {
        fontSize: 18,
      },
    },
    h3: {
      fontWeight: 900,
      fontSize: 24,
      '@media (max-width:600px)': {
        fontSize: 12,
      },
    },
    h4: {
      fontWeight: 900,
      fontSize: 25,
      '@media (max-width:600px)': {
        fontSize: 16,
      },
    },
    h5: {
      fontWeight: 900,
      fontSize: 15,
    },
    button: {
      fontSize: 16,
      fontWeight: 500,
      textTransform: 'none',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      opacity: 0.7,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 300,
      color: '#a1a1a1',
      '@media (max-width:600px)': {
        fontSize: 12,
      },
    },
    h6: {
      fontSize: 16,
      fontWeight: 300,
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': GTWalsheimPro,
      },
    },
    MuiOutlinedInput: {
      root: {
        background: '#f7f7f7',
      },
      input: {
        padding: 14.5,
      },
    },
    MuiButton: {
      sizeLarge: {
        height: 56,
      },
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
      },
      underlineHover: {
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiBackdrop: {
      root: {
        zIndex: 1000,
      },
    },
  },
});

export default theme;
