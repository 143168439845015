import React from 'react';
import { ThemeProvider, CssBaseline, Typography } from '@material-ui/core';

import theme from 'src/themes/defaultTheme';

export default function ({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
