import GTWalsheimProBlack from './GTWalsheimPro-Black.woff2';
import GTWalsheimProBold from './GTWalsheimPro-Bold.woff2';
import GTWalsheimProLight from './GTWalsheimPro-Light.woff2';
import GTWalsheimProMedium from './GTWalsheimPro-Medium.woff2';
import GTWalsheimProRegular from './GTWalsheimPro-Regular.woff2';
import GTWalsheimProThin from './GTWalsheimPro-Thin.woff2';

const GTWalsheimPro = [
    {
      fontFamily: 'GT Walsheim Pro',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: '100',
      src: `
            local('GT Walsheim Pro'),
            url(${GTWalsheimProThin}) format('woff2')
          `,
    },
  {
    fontFamily: 'GT Walsheim Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '300',
    src: `
          local('GT Walsheim Pro'),
          url(${GTWalsheimProLight}) format('woff2')
        `,
  },
  {
    fontFamily: 'GT Walsheim Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '400',
    src: `
          local('GT Walsheim Pro'),
          url(${GTWalsheimProRegular}) format('woff2')
        `,
  },
  {
    fontFamily: 'GT Walsheim Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '500',
    src: `
          local('GT Walsheim Pro'),
          url(${GTWalsheimProMedium}) format('woff2')
        `,
  },
  {
    fontFamily: 'GT Walsheim Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '600',
    src: `
          local('GT Walsheim Pro'),
          url(${GTWalsheimProBold}) format('woff2')
        `,
  },
  {
    fontFamily: 'GT Walsheim Pro',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: '900',
    src: `
          local('GT Walsheim Pro'),
          url(${GTWalsheimProBlack}) format('woff2')
        `,
  },
];

export default GTWalsheimPro;
